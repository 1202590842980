import './style.css';

const Component = () => {

    return(<>

<div className="container-fluid">
              <div className="row home">
                  <center>
                    <img src="/img/logo-white.png" alt="Encrobytes" className="img-responsive" />
                </center>
              </div>
          </div>
         <div className="container">
             <div className="row main-line">
                    <center>
                    <h2>Connecting End-To-End With Technology.</h2>
                    <br />
                    <h4 >
                    
                    Encrobytes, a pioneering software firm, offers customized software, website & mobile app development solutions 
                    and premium SaaS products designed to serve diverse industries.
 

                    </h4>
                    </center>
             </div>
         </div>
         
        <div className="container-fluid products" style={{"backgroundColor":"#f2f2f2"}}>
            <center><h1 style={{"fontSize":"29px"}}>Our Businesses</h1>
            <div className="separator"></div>
            </center>
        
        <section>
          
          <div className="flex-col">
            <div className="cards four">
              <h3>CodeNap - Professional IT Services</h3>
              <p>Custom Websites, Applications & Software Development Services.</p>
              <img src="/img/codenap.png" alt="Encrobytes" style={{"height":"12%","marginTop":"17px","marginLeft":"-0px"}} />
              <button onClick={()=>{window.open('https://codenap.in/');}} className="subscribe-button4">Explore</button>
            </div>
          </div>

          <div className="flex-col">
            <div className="cards four">
              <h3>DeskBox - Coworking Management System</h3>
              <p>Coworking Space & Private Office Management Cloud Software.</p>
              <img src="/img/deskbox.png" alt="Encrobytes" style={{"height":"10.5%","marginTop":"16px","marginLeft":"-5.5px"}} />
              <button onClick={()=>{window.open('https://deskbox.encrobytes.com/');}} className="subscribe-button4">Explore</button>
            </div>
          </div>

          <div className="flex-col">
            <div className="cards four">
              <h3>StayStation - Hotel Management System</h3>
              <p>Hotel & Serviced Apartment Management Cloud Software.</p>
              <img src="/img/staystation.png" alt="Encrobytes" style={{"height":"10.5%","marginTop":"16px","marginLeft":"-5.5px"}} />
              <button onClick={()=>{window.open('https://staystation.encrobytes.com/');}} className="subscribe-button4">Explore</button>
            </div>
          </div>
         
        </section>
        {/* 
        <section className="section2">
          
          <div className="flex-col">
              <div className="cards four">
                <h3>StayStation - Hotel Management System</h3>
                <p>Hotel & Serviced Apartment Management Cloud Software.</p>
                <img src="/img/staystation.png" alt="Encrobytes" style={{"height":"10.5%","marginTop":"16px","marginLeft":"-5.5px"}} />
                <button onClick={()=>{window.open('https://staystation.encrobytes.com/');}} className="subscribe-button4">Explore</button>
              </div>
          </div>

        </section>
        */}
    </div>
    
            <div className="time-date">
            <div className="container-fluid div">
                <div className="row">
                    <center>
                        <div className="col-sm-4">
                            <h3 className="title">Call Us</h3>
                           
                            <h4 style={{"marginTop":"23px"}}>(+91)-8448332454</h4>
                        </div>
                         <div className="col-sm-4">
                            <h3 className="title">Visit Us</h3>
                           
                            <h4 style={{"marginTop":"23px"}}>&Work Coworking, Plot No. 5B, Sector 15A, Crown Plaza, Near Neelam Chowk Metro Gate No. 2, Faridabad, Haryana, 121007</h4>
                        </div>
                        <div className="col-sm-4">
                            <h3 className="title">Email Us</h3>
                           
                            <h4 style={{"marginTop":"23px"}}>ping@encrobytes.com</h4>
                        </div>
                       
                    </center>
                    
                </div>
            </div>
        </div>
      <footer className="footer-distributed">
    
        
    
                <div className="footer-left">
    
                    <p className="footer-links">
                        <img src="/img/logo-white1.png" alt="Encrobytes" className="img-responsive" style={{"height":"5vh", "marginLeft":"-1px"}} />
    
                    </p>
    
                    <p style={{"marginTop":"0px"}}>Copyright &#169; 2024 Encrobytes. All Rights Reserved.</p>
                    
                    
                </div>
    
            </footer>
     

    </>)
}

export default Component;